.swiper-button-next {
	color: unset;
	background-image: unset;
}	

.swiper-button-prev {
	color: unset;
	background-image: unset;
}	

.swiper-button-prev, .swiper-button-next {
    color: #14263C !important;
}